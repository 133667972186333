"use strict";

/*
 *	jquery.suggest 1.1 - 2007-08-06 MODIFIED BY VNE, MODIFID BY MAK and SOL
 *
 *	Uses code and techniques from following libraries:
 *	1. http://www.dyve.net/jquery/?autocomplete
 *	2. http://dev.jquery.com/browser/trunk/plugins/interface/iautocompleter.js
 *
 *	All the new stuff written by Peter Vulgaris (www.vulgarisoip.com)
 *	Feel free to do whatever you want with this file
 *
 */

var currentValue = "",
  selectedValue = "",
  selectedClass = "",
  $currentResult,
  booAbortXHR;
(function ($) {
  "use strict";

  $.suggest = function (input, options) {
    var $input = $(input).attr("autocomplete", "off"),
      $inputId = input.id,
      timeout = false,
      // hold timeout ID for suggestion results to appear
      prevLength = 0,
      // last recorded length of $input.val()
      cache = [],
      // cache MRU list
      cacheSize = 0,
      // size of cache in chars (bytes?)
      overSrl = false,
      // indicates, if mouse is over result layer
      booBtnOpenSuggest = false,
      // Oli: for click on open-button
      $secondaryContentField = options.secondaryContentFieldId !== "" ? $("#" + options.secondaryContentFieldId) : null,
      $tertiaryContentField = options.tertiaryContentFieldId !== "" ? $("#" + options.tertiaryContentFieldId) : null,
      $layerId = options.layerFieldId !== "" ? options.layerFieldId : "",
      $results = $("<ul></ul>"),
      // Define suggest template
      sHtmlLayer = "<div id='" + $layerId + "' class='ac_results layerBox'><div class='boxContent '></div></div>",
      div1 = $('#editLocationForm', '#content-modal').length == 1 ? $(sHtmlLayer).appendTo("#content-modal") : $(sHtmlLayer).appendTo("body"); // append template to body and add result

    function processKey(e) {
      //console.log("processKey(",e,") called");
      // handling up/down/escape requires results to be visible
      // handling enter/tab requires that AND a result to be selected
      booAbortXHR = false;
      if (/27$|38$|40$/.test(e.keyCode) && div1.is(":visible") || e.keyCode === 9 || /^13$|^9$/.test(e.keyCode) && getCurrentResult()) {
        if (e.keyCode !== 9) {
          if (e.preventDefault) {
            e.preventDefault();
          }
          if (e.stopPropagation) {
            e.stopPropagation();
          }
          e.cancelBubble = true;
          e.returnValue = false;
        }
        switch (e.keyCode) {
          case 38:
            // up
            prevResult();
            $currentResult = getCurrentResult();
            if ($currentResult && $currentResult[0].className.indexOf("selectable") === -1) {
              prevResult();
            }
            break;
          case 40:
            // down
            nextResult();
            $currentResult = getCurrentResult();
            if ($currentResult && $currentResult[0].className.indexOf("selectable") === -1) {
              nextResult();
            }
            break;
          case 9:
            // tab
            booAbortXHR = true; // abort suggest
            if (getCurrentResult()) {
              selectCurrentResult();
            } else {
              div1.hide();
            }
            break;
          case 13:
            // return
            selectCurrentResult();
            break;
          case 27:
            //	escape
            div1.hide();
            break;
        }
      } else if ($input.val().length !== prevLength || $input.val().length === 0) {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(suggest, options.delay);
        prevLength = $input.val().length;
      }
    }
    function suggest() {
      var q = $input.val().trim();

      //eliminate [ and ]
      q = q.replace(/[\[\]<>]/g, "");
      currentValue = q;
      if (q.length >= options.minchars || booBtnOpenSuggest) {
        booBtnOpenSuggest = false;
        var cached = checkCache(q);
        if (cached) {
          if (booAbortXHR === true) {
            booAbortXHR = false;
            return;
          }
          displayItems(cached.items);
        } else {
          if (booAbortXHR === true) {
            booAbortXHR = false;
            return;
          }
          $.get(options.source, {
            q: q,
            selectedValue: selectedValue,
            selectedClass: selectedClass
          }, function (txt) {
            div1.hide();
            var items = parseTxt(txt, q);
            displayItems(items);
            resetPosition();
            addToCache(q, items, txt.length);
          });
        }
      } else {
        div1.hide();
      }
    }
    function resetPosition() {
      // Use refererElement if available otherwise use standard $input

      var $referer = options.refererElement ? options.refererElement : $input,
        offset = $referer.offset(),
        nWidth = $referer.outerWidth() + 3;
      //console.log("input =", input, "| nWidth = ", nWidth);

      // add scroll-position to offsets if editLocationForm is inside content-modal
      if ($('#editLocationForm', '#content-modal').length == 1) {
        offset.top = offset.top - $("#content-modal").offset().top;
        offset.left = offset.left - $("#content-modal").offset().left;
      }
      div1.css({
        top: offset.top + $referer[0].offsetHeight + "px",
        left: offset.left + "px",
        "min-width": "10em",
        width: nWidth > 0 ? nWidth : "14.5em"
      });
    }
    function checkCache(q) {
      for (var i = 0; i < cache.length; i++) {
        if (cache[i].q === q) {
          cache.unshift(cache.splice(i, 1)[0]);
          return cache[0];
        }
      }
      return false;
    }
    function addToCache(q, items, size) {
      while (cache.length && cacheSize + size > options.maxCacheSize) {
        var cached = cache.pop();
        cacheSize -= cached.size;
      }
      cache.push({
        q: q,
        size: size,
        items: items
      });
      cacheSize += size;
    }
    div1.find(".boxContent").append($results);

    //resetPosition();
    $(window).on("load", resetPosition) // just in case user is changing size of page while loading
    .on("resize", resetPosition);

    /*div1.hover(function(){
    	overSrl = true;
    },function(){
    	overSrl = false;
    });*/
    // replacement for obove hover-code
    div1.on("mouseenter", function () {
      overSrl = true;
    }).on("mouseleave", function () {
      overSrl = false;
    });
    $input.on("blur", function () {
      if (overSrl === false) {
        setTimeout(function () {
          div1.hide();
        }, 200);
      }
    });

    // help IE6 users if possible - EDITED BY MAK
    if ($.fn.bgiframe) {
      try {
        div1.bgiframe();
      } catch (e) {}
    }

    // I really hate browser detection, but I don't see any other way
    /*if ($.browser.mozilla)
    	$input.keypress(processKey);	// onkeypress repeats arrow keys in Mozilla/Opera
    else*/
    $input.on("keydown", processKey); // onkeydown repeats arrow keys in IE/Safari

    // abort suggest when selecting another field
    $input.on("blur", function () {
      booAbortXHR = true;
    });

    // ******************************************************
    // From Oli: click on user defined button to open suggest
    if (options.btnForceSuggest) {
      options.btnForceSuggest.on("click", function (e) {
        //console.log("suggest(", e, ") wird geöffnet");
        var $this = $(this);
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        if (div1.is(":visible")) {
          div1.hide();
          $this.removeClass("closeDropdown");
        } else {
          booBtnOpenSuggest = true;
          suggest();
          $this.addClass("closeDropdown");
        }
      });
    }
    if (options.suggestOnFocus) {
      $input.focus(suggest);
    }

    // Oli: hide results on click
    $(document).on("click", function () {
      //console.log("body clicked");

      // reset arrow if defined button available
      if (options.btnForceSuggest) {
        options.btnForceSuggest.removeClass("closeDropdown");
      }
    });
    function displayItems(items) {
      if (!items) {
        return;
      }
      if (!items.length) {
        div1.hide();
        return;
      }
      var separatorFound = "false",
        html = "",
        no;
      for (var i = 0; i < items.length; i++) {
        /* Naja, das ist halt ein bescheidenes Plugin ... Muss komplett ersetzt werden. */
        /* jshint maxcomplexity: 100 */
        /* jshint maxstatements: 100 */
        if ($inputId === "txtLoc" || $inputId === "txtLocClaim") {
          if (items[i] === "#ResultCode_1#") {
            if (separatorFound === "false") {
              html += "<li class='street title '><h3>STRASSEN</h3><div class='info'>Zu viele Treffer</div></li>";
            } else if (separatorFound === "true") {
              html += "<li class='sep loc title '><h3>ORTE</h3><div class='info'>Zu viele Treffer</div></li>";
            }
          } else if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            if (separatorFound === "false") {
              html += "<li class='street title '><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + no + "</div><h3>STRASSEN</h3></li>";
            } else if (separatorFound === "true") {
              html += "<li class='sep loc title '><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + no + "</div><h3>ORTE</h3></li>";
            }
          } else if (items[i] === "---") {
            separatorFound = "true";
            //html += '<li class="sep"><hr /></li>';
          } else if (separatorFound === "false") {
            html += "<li class='selectable street'>" + items[i] + "</li>";
          } else if (separatorFound === "true") {
            html += "<li class='selectable loc'>" + items[i] + "</li>";
          }
        } else if ($inputId === "txtSearchTerm" || $inputId === "branches" || $inputId === "branches2" || $inputId === "txtCategory" || $inputId === "txtSearchTermClaim") {
          // txtCategory nur für Branchen editieren auf LD-Seiten
          html += "<li class='selectable cat'>" + items[i] + "</li>";
        } else if ($inputId === "txtRecipient") {
          if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>User</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + (no <= 100 ? no : "sehr vielen") + "</div></li>";
          } else {
            html += "<li class='selectable cat'>" + items[i] + "</li>";
          }
        } else if ($inputId === "txtUserFilter" || $inputId === "userName" || $inputId === "userFilter") {
          if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>User</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + (no <= 100 ? no : "sehr vielen") + "</div></li>";
          } else {
            html += "<li class='selectable cat'>" + items[i] + "</li>";
          }
        } else if ($inputId === "searchLoginName") {
          if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>User</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + (no <= 100 ? no : "sehr vielen") + "</div></li>";
          } else {
            html += "<li class='selectable cat'>" + items[i] + "</li>";
          }
        } else if ($inputId === "chgcty-input" || $inputId === "city" || $inputId === "cityReportLocation" || $inputId === "txtLocalityFilter" || $inputId === "txtNewNsLocality" || $inputId === "locEditForm_locality" || $inputId === "widget-city" || $inputId === "addressSearchInputLoc") {
          // Zu viele Treffer
          if (items[0] === "#ResultCode_1#") {
            break;
          } else if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>ORTE</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + no + "</div></li>";
          } else {
            html += "<li class='selectable cat'>" + items[i] + "</li>";
          }
        } else if ($inputId === "locEditForm_categorytext1" || $inputId === "locEditForm_categorytext2") {
          // Zu viele Treffer
          if (items[0] === "#ResultCode_1#") {
            break;
          } else if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>BRANCHEN</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + no + "</div></li>";
          } else {
            html += "<li class='selectable cat' id='" + $inputId + items[i].replace(/\|.*/, "") + "' >" + items[i].replace(/.*\|/, "") + "</li>";
          }
        } else if ($inputId === "locEditForm_categorykey1" || $inputId === "locEditForm_categorykey2") {
          // Zu viele Treffer
          if (items[0] === "#ResultCode_1#") {
            break;
          } else if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>BRANCHEN</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + no + "</div></li>";
          } else {
            html += "<li class='selectable cat' id='" + $inputId + items[i].replace(/^.*\|/, "") + "' >" + items[i].replace(/\|/, " ") + "</li>";
          }
        } else if ($inputId === "locEditForm_zipcode" || $inputId === "zip" || $inputId === "addressSearchInputZip" || $inputId === "plz") {
          // Zu viele Treffer
          if (items[0] === "#ResultCode_1#") {
            break;
          } else if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>ORTE</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + no + "</div></li>";
          } else {
            html += "<li class='selectable cat' id='" + $inputId + items[i].replace(/\|.*/, "") + "' >" + items[i].replace(/.*\|/, "") + "</li>";
          }
        } else if ($inputId === "locKeyEnter" || $inputId === "locEditForm_locKeyEnter") {
          // Zu viele Treffer
          if (items[0] === "#ResultCode_1#") {
            break;
          } else if (items[i].indexOf("#") === 0) {
            no = parseInt(items[i].substring(1, items[i].length - 1), 10);
            html += "<li class='cat title '><h3>ORTE</h3><div class='resultInfo'>" + (no < 10 ? no : "10") + " von " + no + "</div></li>";
          } else {
            html += "<li class='selectable cat' id='" + $inputId + items[i].replace(/\|.*/, "") + "' >" + items[i].replace(/.*\|/, "") + "</li>";
          }
        }
      }
      $results.html(html);
      if ($results.children("li").length > 0) {
        div1.show();
      }
      $results.children("li.selectable").on("mouseenter", function () {
        overSrl = true;
        $results.children("li").removeClass(options.selectClass);
        $(this).addClass(options.selectClass);
        if ($inputId === "chgcty-input") {
          $("#subNavCityHist").show();
        }
      }).on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        selectCurrentResult();
        try {
          if ($("#cityHistLayer").is(":visible")) {
            $("#cityHistLayer").hide();
          }
        } catch (exception) {}
        if ($inputId === "chgcty-input") {
          $("#subNavCityHist").show();
        }
      });
    }
    function parseTxt(txt, q) {
      var items = [],
        tokens = txt.split(options.delimiter);

      // parse returned data for non-empty items
      for (var i = 0; i < tokens.length; i++) {
        var token = String.prototype.trim.call(tokens[i] == null ? "" : tokens[i]);
        if (token) {
          if (token.indexOf("#") !== 0 && options.markMatch) {
            /* jshint loopfunc:true */
            token = token.replace(new RegExp(q + "(?!>)", "ig"), function (q) {
              return "<span class='" + options.matchClass + "'>" + q + "</span>";
            });
            items[items.length] = token;
          } else {
            items[items.length] = token;
          }
        }
      }
      return items;
    }
    function getCurrentResult() {
      if (!$results.is(":visible")) {
        return false;
      }
      $currentResult = $results.children("li." + options.selectClass);
      if (!$currentResult.length) {
        $currentResult = false;
      }
      return $currentResult;
    }
    function selectCurrentResult() {
      $currentResult = getCurrentResult();
      if ($currentResult[0].className.indexOf("selectable") !== -1) {
        if ($currentResult) {
          var newValue = $currentResult.text().replace(/ .*/, "");
          if ($inputId === "locEditForm_zipcode" || $inputId === "zip" || $inputId === "addressSearchInputZip" || $inputId === "plz") {
            if (newValue.match(/^\d{5}$/)) {
              $input.val(newValue);
            }
          } else if ($inputId === "locEditForm_categorykey1" || $inputId === "locEditForm_categorykey2") {
            if (newValue.match(/^\d+$/)) {
              $input.val(newValue);
            }
          } else {
            $input.val($currentResult.text());
          }
          div1.hide();
          if (options.onSelect) {
            options.onSelect.apply($input[0]);
          }
          if ($secondaryContentField !== null) {
            var secondaryContent = $currentResult.attr("id").replace($inputId, "");
            if ($secondaryContentField.hasClass("placeholder")) {
              $secondaryContentField.removeClass("placeholder");
            }
            $secondaryContentField.val(secondaryContent);
          }
          if ($tertiaryContentField !== null) {
            var tertiaryContent = $currentResult.attr("id").replace($inputId, "");
            if ($tertiaryContentField.hasClass("placeholder")) {
              $tertiaryContentField.removeClass("placeholder");
            }
            $tertiaryContentField.val(tertiaryContent);
          }
          if (options.suggestOnFocus) {
            div1.hide();
            // if mouse is used to select result,
            // focus is lost unless....
            $input.off("focus", suggest) // don't cause a popup
            .focus().on("focus", suggest);
          }

          //search a valid locality for addMissingLocationForm
          if ($("#glMlocMap").length === 1 && ($inputId === "zip" || $inputId === "city")) {
            GlMap.searchMlocAddress(false);
          }
        }
      }
    }
    function nextResult() {
      $currentResult = getCurrentResult();
      if ($currentResult) {
        $currentResult.removeClass(options.selectClass).next().addClass(options.selectClass);
      } else {
        // .slice() is bug fix, :first-child doesn't seem to work in IE6
        $results.children("li").slice(0, 1).addClass(options.selectClass);
      }
    }
    function prevResult() {
      $currentResult = getCurrentResult();
      if ($currentResult) {
        $currentResult.removeClass(options.selectClass).prev().addClass(options.selectClass);
      } else {
        $results.children("li:last-child").addClass(options.selectClass);
      }
    }
  };
  $.fn.suggest = function (source, options) {
    if (!source) {
      return;
    }
    options = options || {};
    options.source = source;
    options.delay = options.delay || 300;
    options.resultsClass = options.resultsClass || "ac_results";
    options.selectClass = options.selectClass || "ac_over";
    options.matchClass = options.matchClass || "ac_match";
    options.minchars = options.minchars || 1;
    options.delimiter = options.delimiter || "\n";
    options.onSelect = options.onSelect || false;
    options.maxCacheSize = options.maxCacheSize || 65536;
    // from Oli:
    options.btnForceSuggest = options.btnForceSuggest || false; // Defines button to open suggest
    options.refererElement = options.refererElement || false; // Defines referer element (used to align and size the tooltip)
    options.suggestOnFocus = options.suggestOnFocus || false;
    options.secondaryContentFieldId = options.secondaryContentFieldId || "";
    options.tertiaryContentFieldId = options.tertiaryContentFieldId || "";
    options.markMatch = options.markMatch || true;
    options.layerFieldId = options.layerFieldId || "";
    this.each(function () {
      // Fehlermeldungen deaktiviert, da Plugin
      /* jshint nonew:false */
      new $.suggest(this, options);
    });
    return this;
  };

  // ****************************
  // Public functions
})(jQuery);
function getWordsWithoutNumbers(str) {
  "use strict";

  var result = "";
  var a = str.split(" ");
  for (var i = 0; i < a.length; ++i) {
    var token = a[i];
    if (token.match("^[^0-9]+$")) {
      if ("" === result) {
        result = token;
      } else {
        result = result + " " + token;
      }
    }
  }
  return result;
}
function getWordsWithoutComas(str) {
  "use strict";

  var result = "";
  var a = str.split(" ");
  for (var i = 0; i < a.length; ++i) {
    var token = a[i];
    if ((token.indexOf(",") === 0 || token.indexOf(";") === 0) && token.length > 1) {
      token = token.substring(1);
    }
    if (token.match("^[^,;]+$")) {
      if ("" === result) {
        result = token;
      } else {
        result = result + " " + token;
      }
    }
  }
  return result;
}

/**
 * @expose
 * @param obj
 */
/*exported itemSelected */
function itemSelected(obj) {
  "use strict";

  var prevSelectedValue = selectedValue,
    prevSelectedClass = selectedClass;

  //console.log("prevSelectedValue="+prevSelectedValue);
  //console.log("prevSelectedClass="+prevSelectedClass);

  selectedValue = obj.value;

  //console.log("currentValue="+currentValue);

  $currentResult.removeClass("ac_over");
  $currentResult.removeClass("selectable");

  //className has to be 'loc', 'street' or 'cat', therefore remove other classes before
  selectedClass = $currentResult[0].className;

  //console.log("selectedValue="+selectedValue);
  //console.log("selectedClass="+selectedClass);

  if (prevSelectedClass === "" || selectedClass === prevSelectedClass || currentValue.indexOf(prevSelectedValue) === -1) {
    obj.value = selectedValue + " ";
  } else {
    var locQ = currentValue.substring(prevSelectedValue.length + 1, currentValue.length);

    //console.log("locQ before ="+locQ);

    locQ = getWordsWithoutComas(locQ);
    if (prevSelectedClass === "street") {
      locQ = getWordsWithoutNumbers(locQ);
    }

    //console.log("locQ after ="+locQ);
    //console.log("substr="+currentValue.substring(0,currentValue.length-locQ.length));

    var searchPos = currentValue.lastIndexOf(locQ);

    //console.log("searchPos="+searchPos);

    obj.value = currentValue.substring(0, searchPos) + selectedValue + " ";
  }
  obj.focus();
  //only needed for IE, to set cursor at the end of text
  obj.value = obj.value;
}